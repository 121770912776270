import { gql }                                 from "@apollo/client";
import { MAILBOX_FRAGMENT }                    from "../graph/operations.graphql";
import { USER_FRAGMENT }                       from "../graph/operations.graphql";
import { USER_NOTIFICATION_CONTROLS_FRAGMENT } from "../graph/operations.graphql";
import { DOCUMENT }                            from "../graph/operations.graphql";
import { NODE_FRAGMENT }                       from "../graph/operations.graphql";
import { NOTIFICATION_FRAGMENT }               from "../graph/operations.graphql";
import { USER_HISTORY_BOOKMARK_FRAGMENT }      from "../graph/operations.graphql";
import { MEMBER_OWN_FIELDS_FRAGMENT }          from "../graph/operations.graphql";

export const SEND_MAIL = gql`
  mutation SendMail($input:MailSendInput){
    sendMail(input:$input){
      id
    }
  }
`;

export const CREATE_DRAFT_MAIL = gql`
  mutation CreateDraftMail($input:MailSendInput){
    createDraftEmail(input:$input){
      id
      objectId
      conversation {
        id
        objectId
      }
      parties {
        type
        unread
        address
        party {
          ...on Contact {
            ...Node
            emails {
              address
              optOut
              type
              isPrimary
            }
            objectId
            objectName
            objectIcon
            updatedAt
          }
          ...on User {
            ...Node
            email
            objectId
            objectName
            objectIcon
            updatedAt
          }
        }
        metadata {
          isDraft
          labels
          provider
        }
      }
      inReplyTo {
        id
        objectId
      }
    }
  }
  ${NODE_FRAGMENT}
`;

export const SAVE_DRAFT_MAIL = gql`
  mutation SaveDraftMail($objectId:String,$input:MailSendInput){
    saveDraftEmail(objectId:$objectId,input:$input) {
      id
      objectId
      conversation {
        id
        objectId
      }
      parties {
        type
        unread
        address
        party {
          ...on Contact {
            ...Node
            emails {
              address
              optOut
              type
              isPrimary
            }
            objectId
            objectName
            objectIcon
            updatedAt
          }
          ...on User {
            ...Node
            email
            objectId
            objectName
            objectIcon
            updatedAt
          }
        }
        metadata {
          isDraft
          labels
          provider
        }
      }
      inReplyTo {
        id
        objectId
      }
    }
  }
  ${NODE_FRAGMENT}
`;

export const SEND_DRAFT_MAIL = gql`
  mutation SendDraftMail($objectId:String,$input:MailSendInput){
    sendDraftEmail(objectId:$objectId,input:$input){
      id
      objectId
      conversation {
        id
        objectId
      }
      parties {
        type
        unread
        address
        party {
          ...on Contact {
            ...Node
            emails {
              address
              optOut
              type
              isPrimary
            }
            objectId
            objectName
            objectIcon
            updatedAt
          }
          ...on User {
            ...Node
            email
            objectId
            objectName
            objectIcon
            updatedAt
          }
        }
        metadata {
          isDraft
          labels
          provider
        }
      }
      inReplyTo {
        id
        objectId
      }
    }
  }
  ${NODE_FRAGMENT}
`;

export const DELETE_DRAFT_MAIL  = gql`
  mutation DeleteDraftMail($objectId: String){
    deleteDraftEmail(objectId: $objectId) {
      objectId
    }
  }
`

export const UPDATE_TASK_DONE = gql`
  mutation UpdateTaskDone($id:ID!, $done: Boolean!){
    updateTask(input: {
      id: $id,
      fields: {
        done: $done
      }
    }){
      task {
        id
        done
      }
    }
  }
`;
export const UPDATE_TASK_DUE_DATE = gql`
  mutation UpdateTaskDueDate($id:ID!, $dueDate: Date!){
    updateTask(input: {
      id: $id,
      fields: {
        dueDate: $dueDate
      }
    }){
      task {
        id
        dueDate
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendPhoneMessage($from:String!, $to:String!, $message:String, $loanEstimateId:String, $scopeId:ID, $templateId:String, $attachments:[Attachment]){
    sendPhoneMessage(from:$from, to:$to, message:$message, scopeId:$scopeId, loanEstimateId:$loanEstimateId, templateId:$templateId, attachments:$attachments)
  }
`;

export const MARK_NOTIFICATIONS_AS_READ = gql`
  mutation MarkNotificationsAsRead($ids:[ID]) {
    markNotificationsAsRead(ids: $ids) {
      id
      objectId
      unread
    }
  }
`;

export const MARK_CALLS_AS_READ = gql`
  mutation MarkCallsAsRead($ids:[ID],$scope:String) {
    markCallsAsRead(ids: $ids,scope:$scope)
  }
`;
export const MARK_MESSAGES_AS_READ = gql`
  mutation MarkMessagesAsRead($ids:[ID],$scope:String) {
    markMessagesAsRead(ids: $ids,scope:$scope)
  }
`;

export const ASSIGN_LEAD_TO_CONFERENCE = gql`
  ${MEMBER_OWN_FIELDS_FRAGMENT}
  mutation UpdateConferenceLead($id:ID!, $fields:UpdateConferenceFieldsInput!){
    updateConference(input: {id: $id,fields: $fields}) {
      conference {
        id
        scope {
          ...on Document {
            objectName
            objectId
            objectIcon
          }
          ...on Node {
            id
          }
          ...on Lead {
            leadStatus{
              status
            }
            members{
              ...MemberOwnFields
              contact{
                id
                firstName
                lastName
                middleName
                types
              }
            }
          }
        }
      }
    }
  }
`;
export const VIEWER_HISTORY_BOOKMARK = gql`
  query ViewerBookmarksAndhistory {
    viewer {
      user {
        id
        objectId
        ...UserNotificationControls
        ...UserHistoryAndBookmark
      }
    }
  }
  ${USER_NOTIFICATION_CONTROLS_FRAGMENT}
  ${USER_HISTORY_BOOKMARK_FRAGMENT}
`;
export const GET_UNREAD_NOTIFICATIONS = gql`
  query GetUnreadNotifications {
    notifications(where: {unread: {equalTo: true},type: {in: ["sms","email","bulk_email", "bulk_sms","reminder","missed_call","milestone_comment"]},record: {exists: true}},order: [createdAt_DESC]){
      edges {
        node {
          ...Notification
        }
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const GET_ALL_NOTIFICATIONS = gql`
  query GetAllNotifications($type: StringWhereInput,$before:String,$after:String,$first:Int = 15) {
    notifications(where: {record: {exists: true}, type: $type},order: [createdAt_DESC,objectId_DESC], before:$before, after:$after, first: $first){
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          ...Notification
        }
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const GET_DISTRIBUTED_LEAD_UNREAD_NOTIFICATIONS = gql`
  query GetDistributedLeadUnreadNotifications {
    notifications(where: {unread: {equalTo: true},type: {equalTo: "distributed_lead"}, record: {exists: true}},order: [createdAt_DESC]){
      count
      edges {
        node {
          ...Notification
        }
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;


export const SUBSCRIBE_DISTRIBUTED_LEAD_UNREAD_NOTIFICATIONS = gql`
  subscription SubscribeDistributedLeadUnreadNotification {
    notifications(where: {unread: {equalTo: true},type: {equalTo: "distributed_lead"}, record: {exists: true}}) {
      event
      node {
        ...Notification
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const SUBSCRIBE_ALL_NOTIFICATIONS = gql`
  subscription SubscribeAllNotifications($type: StringWhereInput) {
    notifications(where: {record: {exists: true}, type: $type}) {
      event
      node {
        ...Notification
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export const MAIL_DOMAIN_FRAGMENT = gql`
  fragment MailDomain on MailDomain {
    id
    objectId
    createdAt
    disabled
    updatedAt
    domain
    useForOut
    valid
    provider {
      type
      tenantId
      clientId
      sync
      returnUrl
    }
    dns {
      type
      valid
      data
      host
    }
    apiKey
    aliases
  }
`;

export const PHONE_MESSAGE_TEMPLATE_FRAGMENT = gql`
  ${NODE_FRAGMENT}
  ${DOCUMENT}
  fragment PhoneMessageTemplate on PhoneMessageTemplate {
    ...Node
    ...Document
    text
    type
    owner {
      ...Node
      objectId
      objectName
      objectIcon
      updatedAt
      createdAt
      firstName
      lastName
      role
    }
    disabled
    title
    loanProposal
  }
`;

export const GET_MESSAGE_TEMPLATES = gql`
  ${PHONE_MESSAGE_TEMPLATE_FRAGMENT}
  query GetMessageTemplates(
    $noOwner: PhoneMessageTemplateWhereInput,
    $owner: PhoneMessageTemplateWhereInput,
    $skipOwner:Boolean = false,
    $skipNoOwner:Boolean = false,
    $first: Int,
    $skip:Int
  ) {
    phoneMessageOwnerTemplates: phoneMessageTemplates(
      where: $owner
      first: $first
      skip: $skip
      order: [createdAt_DESC]
    ) @skip(if: $skipOwner) {
      count
      edges {
        node {
          ...PhoneMessageTemplate
        }
      }
      __typename
    }
    phoneMessageNoOwnerTemplates: phoneMessageTemplates(
      where: $noOwner
      first: $first
      skip: $skip
      order: [createdAt_DESC]
    ) @skip(if: $skipNoOwner) {
      count
      edges {
        node {
          ...PhoneMessageTemplate
        }
      }
      __typename
    }
  }
`;

export const QUEUE_STATS_SUBSCRIPTION = gql`
  subscription QueueStatsSubscription {
    queueStats{
      event
      node {
        id
        active
        queue
        priority
        notify
        isPush
        buttonColor
        icon
        webLeadSound {
          sound
          repeat
        }
        phoneLeadSound {
          sound
          repeat
        }
        isNextCall
        leadsAvailable
        leadTakenAt
        leadTakenDaily
        leadTakenMonthly
        leadTakenWeekly
        isLimitExceeded
        coolDownPeriod
        isCoolingDown
        countVisible
        dailyCapVisible
        monthlyCapVisible
        weeklyCapVisible
        coolDownVisible
        dailyCap
        weeklyCap
        monthlyCap
        autoDial
      }
    }
  }
`;

export const TEAM_MEMBERS = gql`
  query TeamMembers($id:ID!,$team:String!) {
    users(where: {team: {equalTo: $team},id: {notEqualTo: $id}}) {
      edges{
        node{
          objectIcon
          objectName
          objectId
          id
        }
      }
    }
  }
`;

//todo tmp remove after old mail compose delete
export const UPDATE_LOAN_PROPOSAL = gql`
  mutation UpdateLoanProposal($id:ID!, $sent:Float!){
    updateLoanProposal(input: {
      id: $id,
      fields: {
        sent: $sent
      }
    }){
      loanProposal {
        id
        sent
      }
    }
  }
`;

export const GENERATE_FLYER = gql`
  mutation GenerateFlyer($flyer:ID!, $scope:ID!, $generationType: GenerationType){
    generateFlyer(scope: $scope, flyer: $flyer, generationType: $generationType){
      ...Node
      ...Document
      mimeType
      name
      size
      url    
    }
  }
  ${NODE_FRAGMENT}
  ${DOCUMENT}
`;

export const GET_LOAN_PROPOSAL_SENT = gql`
  query GetLoanProposalSent($id:ID!){
    loanProposal(id:$id){
      id
      sent
    }
  }
`;
export const UPDATE_LOAN_ESTIMATE_SENT = gql`
  mutation UpdateLoanEstimateSent($id:ID!,$sent:Float!){
    updateLoanEstimate(input: {id: $id,fields: {sent: $sent}}){
      loanEstimate {
        id
        sent
      }
    }
  }
`;
export const GET_LOAN_ESTIMATE_SENT = gql`
  query GetLoanEstimateSent($id:ID!){
    loanEstimate(id:$id){
      id
      sent
    }
  }
`;

//todo tmp remove after old mail compose delete
export const GET_LOAN_PROPOSAL = gql`
  query GetLoanProposal($id:ID!){
    loanProposal(id:$id){
      id
      objectId
      objectName
      applyUrl
      offers {
        edges {
          node {
            objectId
            objectName
            loanCriteria {
              isPmiEligible
              requiresPestInspection
              cashOut
              cltv
              downPayment
              dti
              fico
              financeMip
              financeFf
              firstTimeHomeBuyer
              firstUseOfVaProgram
              loanAmount
              totalLoanAmount
              loanPurpose
              ltv
              mortech {
                lockInDays
                product
                investorIds
                source
                view
                productName
                streamLine
                streamLineWithCredit
                streamLineWithAppraisal
                hudReo
                __typename
              }
              property {
                occupancy
                propertyAddress {
                  propertyCounty
                  state
                  zipCode
                  __typename
                }
                type
                value
                currentMortgageBalance
                __typename
              }
              waiveEscrow
              veteranStatus
              secondaryFinancing
              __typename
            }
            selectedRate {
              lastUpdate
              pricingStatus
              productTerm
              productName
              vendorName
              productDesc
              quoteDetails {
                loanAmount
                rate
                apr
                price
                piti
                srp
                monthlyPremium
                __typename
              }
              __typename
            }
            closingCost {
              total
              title {
                total
                insurance
                ownersTitle
                recordingCharges
                transferTax
                settlementFee
                lendersTitle
                __typename
              }
              creditAndOther {
                total
                creditReport
                loanSafe
                mers
                verification
                documentDelivery
                transcript
                __typename
              }
              pmi
              appraisalFee
              discountPoints
              floodCertification
              adminFee
              mip
              fundingFee
              pestInspection
              docReview
              attorney
              survey
              subordination
              condoCertificate
              earnestMoneyPaid
              sellerConcession
              __typename
            }
            prepaid {
              total
              dailyInterest
              daysToPayInterest
              interest
              yearlyInsurance
              monthsToPayInsurance
              monthlyInsurance
              insurance
              monthsToPayTax
              monthlyTax
              tax
              lockInFee
              __typename
            }
            monthlyPayment {
              total
              pi
              insurance
              tax
              mi
              pmi
              __typename
            }
            lenderCredits
            totalClosingCost
            createdAt
            updatedAt
            id
            __typename
          }
          __typename
        }
        __typename
      }
      image {
        name
        url
        mimeType
      }
      file {
        name
        url
        mimeType
      }
      sent
      createdAt
      updatedAt
      id
      objectIcon
    }
  }
`;

export const GET_MAIL_DOMAINS = gql`
  query GetMailDomains {
    mailDomains {
      edges {
        node {
          ...MailDomain
        }
      }
    }
  }
  ${MAIL_DOMAIN_FRAGMENT}
`;
export const GET_TAGS = gql`
  query GetTags($className: String!, $limit: Int $search: String!){
    tags(className: $className, search: $search, limit:$limit)
  }
`;

export const ALL_USERS = gql`
  query AllUsers($first:Int = 1000) {
    users(first:$first){
      edges{
        node{
          ...Node
          ...Document
          firstName
          lastName
          username
          role
          status
          online
          team
          nmlsId
          reviewUrl
          calendar
          applyUrl
          positionName
          lastNotificationSeenAt
          bulkPermissions {
            email
            sms
          }
          dataTableView {
            create
            share
          }
          tags
          deactivated
          voicemailUrl
          licensedStates
          email
          accountLockoutExpiresAt
        }
      }
    }
  }
  ${NODE_FRAGMENT}
  ${DOCUMENT}
`;

export const ASSIGNMENT_ACTIVITY_FRAGMENT = gql`
  fragment AssignmentActivity on AssignmentActivity{
    assignmentEvent
    assignmentSubject {
      ... on Document {
        objectId
        objectIcon
        objectName
      }
    }
    assignedTo {
      objectId
      objectIcon
      objectName
    }
    assignedBy {
      objectId
      objectIcon
      objectName
    }
    assignedFrom {
      objectId
      objectIcon
      objectName
    }
  }
`;

export const PROPOSAL_ACTIVITY_FRAGMENT = gql`
  fragment ProposalActivity on ProposalActivity{
    loanEstimate {
      objectId
      objectIcon
      objectName
    }
    proposalGeneratedBy {
      objectId
      objectIcon
      objectName
    }
    proposalSubject {
      objectId
      objectIcon
      objectName
    }
  }
`;

export const STATUS_CHANGE_ACTIVITY_FRAGMENT = gql`
  fragment StatusChangeActivity on StatusChangeActivity {
    statusChangedBy {
      objectId
      objectIcon
      objectName
    }
    statusChangedSubject {
      objectId
      objectIcon
      objectName
    }
    statusNewValue {
      actionId
      actionName
      statusId
      statusName
    }
    statusOldValue {
      actionId
      actionName
      statusId
      statusName
    }
  }
`;

export const EXPORT_ACTIVITY_FRAGMENT = gql`
  fragment ExportActivity on ExportActivity {
    exportedBy {
      objectId
      objectIcon
      objectName
    }
    exportSubject {
      objectId
      objectIcon
      objectName
    }
  }
`;

export const PHONE_CALL_ACTIVITY_FRAGMENT = gql`
  fragment PhoneCallActivity on PhoneCallActivity {
    phoneCall {
      objectId
      objectName
      missed
      calls {
        party {
          ...on User {
            id
          }
          ...on Contact {
            id
          }
        }
      }
      recording {
        objectId
      }
      voicemail {
        objectId
      }
    }
    phoneCallFrom {
      ...on Document{
        objectId
        objectIcon
        objectName
      }
    }
    phoneCallTo {
      ...on Document{
        objectId
        objectIcon
        objectName
      }
    }
    phoneCallSubject {
      ...on Document{
        objectId
        objectIcon
        objectName
      }
    }
  }
`;

export const PHONE_MESSAGE_ACTIVITY_FRAGMENT = gql`
  fragment PhoneMessageActivity on PhoneMessageActivity {
    phoneMessage {
      phoneMessageConversation: conversation {
        id
        objectId
        objectName
        content
        lastUpdatedAt
      }
      loanEstimate {
        objectId
      }
      content
      errorCode
      sid
      objectId
    }
    phoneMessageAttachments
    phoneMessageFrom {
      ...on Document{
        objectId
        objectIcon
        objectName
      }
    }
    bulkConversation {
      objectId
    }
    phoneMessageTo {
      ...on Document{
        objectId
        objectIcon
        objectName
      }
    }
    phoneMessageSubject {
      ...on Document{
        objectId
        objectIcon
        objectName
      }
    }
  }
`;

export const MAIL_MESSAGE_ACTIVITY_FRAGMENT = gql`
  fragment MailMessageActivity on MailMessageActivity {
    mailMessage {
      objectId
      snippet
      text
      subject
      loanEstimate {
        objectId
      }
      conversation {
        objectId
      }
    }
    mailMessageAttachments
    mailMessageFrom {
      ...on Document{
        objectId
        objectIcon
        objectName
      }
    }
    bulkMailConversation {
      objectId
    }
    mailMessageTo {
      ...on Document {
        objectId
        objectName
        objectIcon
      }
    }
    mailMessageSubject {
      ...on Document {
        objectId
        objectName
        objectIcon
      }
    }
  }
`;

export const DISTRIBUTION_ACTIVITY_FRAGMENT = gql`
  fragment DistributionActivity on DistributionActivity {
    distributionEvent
    distributionRule
    distributionQueue {
      ...on DistributionQueue{
        objectId
        objectName
        strategy
      }
    }
    distributedTo {
      objectId
      objectName
      objectIcon
    }
    distributionSubject {
      objectId
      objectName
      objectIcon
    }
  }
`;

export const PRIORITIZATION_ACTIVITY_FRAGMENT = gql`
  fragment PrioritizationActivity on PrioritizationActivity {
    prioritizationEvent
    prioritizationQueue {
      objectId
      objectName
    }
    prioritizationView {
      objectId
      title
    }
    prioritizationUser {
      objectId
    }
    prioritizationSubject {
      objectId
      objectName
      objectIcon
    }
  }
`;

export const OBJECT_MERGE_ACTIVITY_FRAGMENT = gql`
  fragment ObjectMergeActivity on ObjectMergeActivity {
    objectType
    sourceObject
    targetObject
    mergeBy {
      objectId
      objectName
      objectIcon
    }
  }
`;

export const BULK_MAIL_ACTIVITY_FRAGMENT = gql`
  fragment BulkMailActivity on BulkMailActivity {
    bulkMailConversation {
      objectId
    }
    bulkMailCount
    bulkMailCountPerUser {
      user
      count
    }
    bulkMailUserCount
    bulkMailConversationFrom {
      ...on Document{
        objectId
        objectIcon
        objectName
      }
    }
  }
`;

export const BULK_SMS_ACTIVITY_FRAGMENT = gql`
  fragment BulkSMSActivity on BulkSMSActivity {
    bulkSMSConversation {
      objectId
    }
    bulkSMSCount
    bulkSMSUserCount
    bulkSMSCountPerUser {
      user
      count
    }
    bulkSMSConversationFrom {
      ...on Document{
        objectId
        objectIcon
        objectName
      }
    }
  }
`;

export const WORKFLOW_ACTIVITY_FRAGMENT = gql`
  fragment WorkflowActivity on WorkflowActivity {
    title
    message
    activityCreatedBy {
      objectName
      objectId
    }
  }
`;

export const GENERIC_ACTIVITY_FRAGMENT = gql`
  fragment GenericActivity on GenericActivity {
    generatedBy {
      ...on Document{
        objectId
        objectName
        objectIcon
      }
    }
    genericSubject {
      ...on Document{
        objectId
        objectName
        objectIcon
      }
    }
    action
    message
  }
`;

export const ACTIVITY_FRAGMENT = gql`
  fragment Activity on Activity{
    ...Node
    objectId
    updatedAt
    createdAt
    createdBy {
      ... on User {
        id
        objectId
      }
      ... on Contact {
        id
        objectId
      }
    }
    type
    relatedTo
    data {
      ...on AssignmentActivity {
        ...AssignmentActivity
      }
      ...on StatusChangeActivity {
        ...StatusChangeActivity
      }
      ...on PhoneCallActivity {
        ...PhoneCallActivity
      }
      ...on PhoneMessageActivity {
        ...PhoneMessageActivity
      }
      ...on MailMessageActivity {
        ...MailMessageActivity
      }
      ...on DistributionActivity {
        ...DistributionActivity
      }
      ...on PrioritizationActivity {
        ...PrioritizationActivity
      }
      ...on ObjectMergeActivity {
        ...ObjectMergeActivity
      }
      ...on GenericActivity {
        ...GenericActivity
      }
      ...on BulkMailActivity {
        ...BulkMailActivity
      }
      ...on BulkSMSActivity {
        ...BulkSMSActivity
      }
      ...on ProposalActivity{
        ...ProposalActivity
      }
      ...on ExportActivity{
        ...ExportActivity
      }
      ...on WorkflowActivity{
        ...WorkflowActivity
      }
    }
  }
  ${OBJECT_MERGE_ACTIVITY_FRAGMENT}
  ${BULK_MAIL_ACTIVITY_FRAGMENT}
  ${BULK_SMS_ACTIVITY_FRAGMENT}
  ${DISTRIBUTION_ACTIVITY_FRAGMENT}
  ${PRIORITIZATION_ACTIVITY_FRAGMENT}
  ${STATUS_CHANGE_ACTIVITY_FRAGMENT}
  ${ASSIGNMENT_ACTIVITY_FRAGMENT}
  ${MAIL_MESSAGE_ACTIVITY_FRAGMENT}
  ${PHONE_MESSAGE_ACTIVITY_FRAGMENT}
  ${PHONE_CALL_ACTIVITY_FRAGMENT}
  ${PROPOSAL_ACTIVITY_FRAGMENT}
  ${GENERIC_ACTIVITY_FRAGMENT}
  ${WORKFLOW_ACTIVITY_FRAGMENT}
  ${EXPORT_ACTIVITY_FRAGMENT}
  ${NODE_FRAGMENT}
`;

export const EMAIL_TEMPLATE_FRAGMENT = gql`
  fragment EmailTemplate on EmailTemplate {
    createdAt
    enabled
    content
    jsonContent
    title
    subject
    type
    applyTo
    availableFor
    owner {
      role
      ...Node
      ...Document
    }
    ...Node
    ...Document
  }
  ${NODE_FRAGMENT}
  ${DOCUMENT}
`;

export const FLYER_FRAGMENT = gql`
  fragment Flyer on Flyer {
    createdAt
    enabled
    content
    jsonContent
    title
    applyTo
    availableForType
    availableFor
    previewSettings {
      mailbox {
        ...Mailbox
      }
      scope {
        ...Node
        ...Document
        ...on Lead {
          contact {
            objectId
            objectName
            firstName
            lastName
            middleName
            emails {
              type
              optOut
              address
              isPrimary
            }
            phones {
              isPrimary
              optOut
              callOptOut
              number
            }
          }
        }
      }
      user {
        ...Node
        ...Document
        ...User
      }
    }
    owner {
      role
      ...Node
      ...Document
    }
    ...Node
    ...Document
  }
  ${MAILBOX_FRAGMENT}
  ${USER_FRAGMENT}
  ${NODE_FRAGMENT}
  ${DOCUMENT}
`;
