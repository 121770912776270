import React                         from "react";
import { FC }                        from "react";
import { useMemo }                   from "react";
import { useContext }                from "react";
import { useForm }                   from "@relcu/form";
import { Field }                     from "@relcu/form";
import { FieldArray }                from "@relcu/form";
import { FormSpy }                   from "@relcu/form";
import { useSource }                 from "@relcu/ui";
import { CheckboxGroup }             from "@relcu/rc";
import { Checkbox }                  from "@relcu/rc";
import { FormField }                 from "@relcu/rc";
import { Avatar, Stack, Typography } from "@relcu/rc";
import { transformNameToLabel }      from "../../../../../../utils/helpers";
import { toFirstLower }              from "../../../../../../utils/helpers";
import { DetailsContainer }          from "../../../../../Generation";
import { DetailsField }              from "../../../../../Generation";
import { useSchemaField }            from "../../../../useSchemaField";
import { InjectionAddon }            from "../InjectionAddon/InjectionAddon";
import { MailTemplateContext }       from "../Content";

interface TemplateDetailsProps {
  owner?: string;
  name?: string;
  replacements: any[];
}

export const TemplateDetails: FC<TemplateDetailsProps> = React.memo(function TemplateDetails(props) {
  const { replaceableFieldsSources } = useContext(MailTemplateContext);
  let optionalField = useMemo(() => replaceableFieldsSources.find(r => r != "Contact" && r != "LoanProposal" && r != "Lead"), [replaceableFieldsSources]);
  const { options } = useSchemaField("User", "role");
  const { $object } = useSource();
  const form = useForm();
  function compareWithInitial(initial, current) {
    return initial?.every(i => {
      return current.includes(i);
    });
  }

  function handleApplyToChange(value, formValue) {
    let changedValue = [...value];
    if (changedValue.includes("loanProposal") && !changedValue.includes("lead")) {
      changedValue.push("lead");
    }

    if (optionalField) {
      optionalField = toFirstLower(optionalField);
      if (!formValue.includes("lead") && changedValue.includes("lead") && changedValue.includes(optionalField)) {
        changedValue = changedValue.filter(cv => cv != optionalField);
      } else if (!formValue.includes(optionalField) && changedValue.includes(optionalField) && changedValue.includes("lead")) {
        changedValue = changedValue.filter(cv => cv != "lead" && cv != "loanProposal");
      }
    }

    return changedValue;
  }

  return <DetailsContainer>
    <DetailsField style={{ width: "50%" }}>
      <DetailsField.Title>General</DetailsField.Title>
      <Stack direction={"column"} alignItems={"flex-start"} childrenRenderMode={"clone"}
             style={{ alignSelf: "stretch" }}>
        <FormField
          name="title"
          label="Template name"
          required
          validate={(value) => {
            if (value?.trim() === "") {
              return `Template name is required.`;
            }
          }}
          groupProperties={{ style: { alignSelf: "stretch" } }}
        />
        <FormField
          properties={{ className: "injectable-input" }}
          required
          name="subject"
          label="Subject"
          validate={(value) => {
            if (value?.trim() === "") {
              return `Subject is required.`;
            }
          }}
          addon={
            <InjectionAddon
              data={props.replacements}
              controlId={".injectable-input"}
              onChange={(newValue) => form.change("subject", newValue)}/>
          }
          groupProperties={{ style: { alignSelf: "stretch" } }}
        />
      </Stack>
    </DetailsField>
    {/*<Stack direction={"column"} alignItems={"flex-start"} spacing={16} childrenRenderMode={"clone"}*/}
    {/*       style={{ width: "50%" }}>*/}
    {/*  <Typography weights={"medium"} variant={"base16"} lineHeight={"lh20"}>General</Typography>*/}
    {/* */}
    {/*</Stack>*/}
    <DetailsField>
      <DetailsField.Title withGap={false}>Apply to</DetailsField.Title>
      <DetailsField.SubTitle>Select when this template should be available for use.</DetailsField.SubTitle>
      <FieldArray
        name="applyTo"
        validate={(value, allValues, meta) => {
          if (meta.modified && value?.length == 0) {
            return "Please select at least one option.";
          }
        }}>
        {
          ({ fields, meta }) => {
            return (
              <>
                <CheckboxGroup
                  value={fields.value}
                  onChange={(value) => {
                    form.change("applyTo", handleApplyToChange(value, fields.value));
                  }}>
                  {
                    replaceableFieldsSources.map((source, index) => {
                      return <Checkbox value={toFirstLower(source)} key={index}>
                        {transformNameToLabel(source)}
                      </Checkbox>;
                    })
                  }
                </CheckboxGroup>
                {
                  meta.error &&
                  <DetailsField.State variant={"error"}>
                    {meta.error}
                  </DetailsField.State>
                }
              </>
            );
          }
        }
      </FieldArray>
      <FormSpy subscription={{ values: true, initialValues: true }}>
        {
          ({ values, initialValues }) => {
            return compareWithInitial(initialValues.applyTo, values.applyTo) ?
              null :
              <DetailsField.State variant={"warning"}>
                This change may result in errors with replacement parameters when you use the email template.
              </DetailsField.State>;
          }
        }
      </FormSpy>
    </DetailsField>
    <DetailsField>
      <DetailsField.Title>Available for</DetailsField.Title>
      <FieldArray
        name="availableFor"
        validate={(value, allValues, meta) => {
          if (meta.modified && value?.length == 0) {
            return "Please select at least one option.";
          }
        }}>
        {
          ({ fields, meta }) => {
            return (
              <>
                <CheckboxGroup value={fields.value} onChange={(value) => form.change("availableFor", value)}>
                  <Checkbox value={"manual"}>Manual</Checkbox>
                  {
                    $object.className == "Settings" &&
                    <Checkbox value={"automated"}>Automated</Checkbox>
                  }
                  <Checkbox value={"bulk"}>Bulk</Checkbox>
                </CheckboxGroup>
                {
                  meta.error &&
                  <DetailsField.State variant={"error"}>
                    {meta.error}
                  </DetailsField.State>
                }
              </>
            );
          }
        }
      </FieldArray>
    </DetailsField>
    <DetailsField>
      <DetailsField.Title>Status</DetailsField.Title>
      <Field
        name="enabled"
        type="checkbox">
        {({ input }) => {
          return <Checkbox
            checked={input.checked}
            onChange={(value, checked) => input.onChange(checked)}>
            Enable template
          </Checkbox>;
        }}
      </Field>
    </DetailsField>
    <FormSpy subscription={{ values: true }}>
      {({ values }) => {
        const owner = values?.owner;
        const role = options.find(option => option.value == owner?.role);
        return owner ?
          <>
            <DetailsField>
              <DetailsField.Title>Template owner</DetailsField.Title>
              <Stack direction={"row"} alignItems={"center"} spacing={16}>
                <Avatar size={"xl"} src={owner.objectIcon} showIndicator={false}>{owner.objectName}</Avatar>
                <Stack spacing={4} direction={"column"} alignItems={"flex-start"} childrenRenderMode={"clone"}>
                  <Typography variant={"base16"} lineHeight={"lh20"}>{owner.objectName}</Typography>
                  <Typography variant={"base14"} lineHeight={"lh16"} color={"tertiary"}>{role.label}</Typography>
                </Stack>
              </Stack>
            </DetailsField>
          </>
          : null;
      }}
    </FormSpy>
  </DetailsContainer>;
});



