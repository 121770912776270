import React           from "react";
import { FC }          from "react";
import { useContext }  from "react";
import { useEffect }   from "react";
import { useMemo }     from "react";
import EmailEditor     from "react-email-editor";
import { useField }    from "@relcu/form";
import { useForm }     from "@relcu/form";
import { HiddenField } from "@relcu/ui";
import { useAlert }    from "@relcu/ui";
import { useSource }   from "@relcu/ui";
import { fileToBase64 } from "../../../../../utils/helpers";
import { FlyerContext } from "./Content";
import { FlyerPreview } from "./FlyerPreview";

export interface FlyerEditorProps {
  replacements: any[];
  onEditorLoad?(loading: boolean);
}
export const FlyerEditor: FC<FlyerEditorProps> = React.memo(function FlyerEditor(props) {
  const { preview, emailEditorRef } = useContext(FlyerContext);
  const { error } = useAlert();
  const form = useForm();
  const contentField = useField("content");

  const { $viewer } = useSource();
  const replacementParams = useMemo(() => {
    const params = {};
    props.replacements.map(replacement => {
      if (!params[ replacement.role ]) {
        params[ replacement.role ] = {
          name: replacement.role,
          mergeTags: {}
        };
      }

      params[ replacement.role ].mergeTags[ replacement.value ] = {
        value: replacement.value,
        name: replacement.label,
        sample: replacement.value
      };
    });

    return params;
  }, [props.replacements]);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(fileToBase64(reader));
    reader.onerror = reject;
  });

  const onLoad = () => {
    emailEditorRef.current.editor.registerCallback("image", async function (file, done) {
      const data = new FormData();
      const attachment = file.attachments[ 0 ];
      const base64File: any = await toBase64(attachment);
      data.append("data", JSON.stringify({
        content: {
          base64: base64File
        },
        name: attachment.name,
        type: attachment.type
      }));

      fetch("/api/v1/templateFiles", {
        method: "POST",
        headers: {
          "Accept": "application/json"
        },
        body: data
      }).then(response => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          error("Oops something went wrong");
          throw new Error("Oops something went wrong");
        }
      }).then(response => {
        return response.json();
      }).then(data => {
        // Pass the URL back to Unlayer to mark this upload as completed
        done({ progress: 100, url: data.url });
      });
    });

    const values = form.getState().values;
    if (values.jsonContent) {
      emailEditorRef.current.editor.loadDesign(values.jsonContent);
    }
  };

  const onReady = () => {
    // editor is ready
    props.onEditorLoad(false);
    emailEditorRef.current?.editor?.addEventListener("design:updated", function (data) {
      emailEditorRef.current.editor.exportHtml((data) => {
        const { design, html } = data;
        contentField.input.onChange(html); //todo do not change this it need to affect on dirty field
        form.change("jsonContent", design);
      });
    });
  };
  useEffect(() => {
    props.onEditorLoad(true);
  }, []);

  return <>
    <HiddenField name={"title"}/>
    <HiddenField name={"availableFor"}/>
    <HiddenField name={"enabled"}/>
    <FlyerPreview/>
    <div style={{ display: preview ? "none" : "flex", flex: 1 }}>
      <EmailEditor
        options={{
          user: {
            id: `${window.location.hostname}:${$viewer.objectId}`
          },
          id: "editor",
          displayMode: "document",
          projectId: window.__CONFIG__.unlayerProjectId,
          tools: {
            social: {
              enabled: true
            },
            timer: {
              enabled: true
            },
            video: {
              enabled: true
            }
          },
          features: {
            userUploads: true,
            imageEditor: {
              enabled: true
            },
            smartMergeTags: true
          },
          mergeTags: replacementParams
        }}
        ref={emailEditorRef}
        onLoad={onLoad}
        onReady={onReady}
      />
    </div>
  </>;
});
