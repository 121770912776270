import React                        from "react";
import { FC }                       from "react";
import { useClassNames }            from "@relcu/rc";
import { Stack }                    from "@relcu/rc";
import { RsRefForwardingComponent } from "@relcu/rc/src/@types/common";
import { WithAsProps }              from "@relcu/rc/src/@types/common";
import "./preview.css"

export interface PreviewComponent extends RsRefForwardingComponent<"div", WithAsProps> {
  RendererContainer: typeof PreviewRenderer;
}

export const Preview: PreviewComponent = React.forwardRef(function Preview(props: WithAsProps) {
  const { className, ...rest } = props;
  const { withClassPrefix, merge } = useClassNames("preview", "rc");
  const classes = merge(
    className,
    withClassPrefix()
  );

  return <Stack childrenRenderMode={"clone"} className={classes}{...rest}/>;
}) as unknown as PreviewComponent

export const PreviewRenderer: FC<WithAsProps> = React.memo(function PreviewRenderer(props) {
  const { className,children, ...rest } = props;
  const { withClassPrefix, merge } = useClassNames("preview-renderer", "rc");
  const classes = merge(
    className,
    withClassPrefix()
  );
  return (
    <Stack alignItems={"flex-start"} direction={"column"} childrenRenderMode={"clone"} className={classes} {...rest}>
      {children}
    </Stack>
  );
});

Preview.RendererContainer = PreviewRenderer;
