import { useRef }                   from "react";
import { useMutation }              from "@apollo/client";
import { useSource }                from "@relcu/ui";
import { MailSendInput }            from "../types/graphql-global-types";
import { findDefaultMailBox }       from "../utils/helpers";
import { getNodeEmail }             from "../utils/helpers";
import { applySignature }           from "../utils/mailUtils";
import { prepareMessageText }       from "../utils/mailUtils";
import { CreateDraftMailVariables } from "./__types__/CreateDraftMail";
import { CreateDraftMail }          from "./__types__/CreateDraftMail";
import { SendMailVariables }        from "./__types__/SendMail";
import { SendMail }                 from "./__types__/SendMail";
import { EmailRendererRef }         from "./EmailVisualizer";
import { CREATE_DRAFT_MAIL }        from "./operations.graphql";
import { SEND_MAIL }                from "./operations.graphql";
import { useMailboxes }             from "./useMailboxes";

export function useMailApi(node?: { __typename: string, objectId?: string, [ k: string ]: any }) {
  const contentRendererRef = useRef<EmailRendererRef>();
  const signatureRendererRef = useRef<EmailRendererRef>();
  const { $viewer: user, $settings } = useSource();
  const { mailBoxes: mBoxes } = useMailboxes();
  const [createDraft, createDraftMailData] = useMutation<CreateDraftMail, CreateDraftMailVariables>(CREATE_DRAFT_MAIL);
  const [send] = useMutation<SendMail, SendMailVariables>(SEND_MAIL);
  const { enabledMailBoxes, mailBoxes, defaultMailbox } = findDefaultMailBox(mBoxes, user.id);
  const enabledForBulk = enabledMailBoxes.filter(m => m.domain.valid && !m.common);

  const nodeEmail = getNodeEmail(node);
  const defaultBulkMailbox = enabledForBulk.at(0);
  const handleMailSubmit = async ({ template, content, ...values }) => {
    if (!values.from) {
      return { from: `From is required.` };
    }

    if (!values.subject) {
      return { subject: `Subject is required.` };
    }
    if (values.to.length == 0) {
      return { to: `To is required.` };
    }

    let html = content;
    let text: string;
    if (template && contentRendererRef.current) {
      html = contentRendererRef.current.html;
      text = contentRendererRef.current.text;
    }
    if (signatureRendererRef.current) {
      html = applySignature(html, signatureRendererRef.current.html);
    }
    return await send({
      variables: {
        input: {
          ...values,
          from: values.from.address,
          html: html,
          text: text || prepareMessageText(html),
          scope: (node.__typename != "Contact" && node.__typename != "User") ? node.id : null
        }
      }
    });
  };
  const createDraftMail = async (input: MailSendInput) => {
    return createDraft({
      variables: {
        input: {
          subject: "New mail subject",
          scope: (node.__typename != "Contact" && node.__typename != "User") ? node.id : null,
          ...input
        }
      }
    });
  };
  return {
    contentRendererRef,
    signatureRendererRef,
    handleSubmit: handleMailSubmit,
    mailBoxes,
    createDraftMailData,
    createDraftMail,
    defaultMailbox,
    defaultBulkMailbox,
    nodeEmail
  };
}
