import { TypePolicy }                 from "@apollo/client";
import { skipLimitPagination }        from "../../utils/helpers";
import { relayStyleCustomPagination } from "../../utils/helpers";

export const Query: TypePolicy = {
  fields: {
    priorities: { merge: false },
    phoneMessages: relayStyleCustomPagination(["where"]),
    emailTemplates: relayStyleCustomPagination(["where"]),
    flyers: relayStyleCustomPagination(["where"]),
    emailMessages: relayStyleCustomPagination(["where", "first", "order"]),
    mailConversations: relayStyleCustomPagination(["where"]),
    phoneMessageConversations: relayStyleCustomPagination(["where"]),
    conferences: relayStyleCustomPagination(["where"]),
    notifications: relayStyleCustomPagination([ "where", "order", "@connection", ["key"]]),
    notes: relayStyleCustomPagination(["where"]),
    // activities: relayStyleCustomPagination(["where", "first", "order"]),
    activities: relayStyleCustomPagination(["where"]),
    tasks: relayStyleCustomPagination(["where"]),
    search: skipLimitPagination(),
    getPmiRates: {
      merge(existing, incoming, { variables, storage }) {
        if (variables?.input?.pmiPartners) {//todo do not touch Object.keys
          const mergedRates = existing.rates ? { ...existing.rates } : {};
          for (let [option, rates] of Object.entries<any>(incoming.rates)) {
            rates.forEach(rate => {
              Object.assign(mergedRates, {
                [ option ]: mergedRates[ option ].map(eRate => eRate.providerId == rate.providerId ? rate : eRate)
              });
            });
          }
          return {
            ...existing,
            rates: mergedRates
          };
        }
        return incoming ?? existing;
      }
    },
    titleFees: {
      merge(existing, incoming, { variables, storage }) {
        if (existing) {
          storage.existing = existing;
        }
        if (variables?.input?.providerIds) {
          return existing?.map(e => incoming.find(i => i.providerId == e.providerId) ?? e);
        }
        return incoming ?? existing;
      }
    },
    leads: relayStyleCustomPagination(["where"]),
    queueStats: {
      merge(existing, incoming) {
        return incoming ?? existing;
      }
    },
    viewer: { merge: true }
  }
};
