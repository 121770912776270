import React                                               from "react";
import { FC, useCallback }                                 from "react";
import { useState }                                        from "react";
import { useMemo }                                         from "react";
import { useMutation }                                     from "@apollo/client";
import { useReactiveVar }                                  from "@apollo/client";
import { Navigate }                                        from "@relcu/react-router";
import { useParams }                                       from "@relcu/react-router";
import { useNavigate }                                     from "@relcu/react-router";
import { Routes }                                          from "@relcu/react-router";
import { Route }                                           from "@relcu/react-router";
import { IconType }                                        from "@relcu/rc";
import { EmptyState }                                      from "@relcu/rc";
import { Badge }                                           from "@relcu/rc";
import { CheckPicker }                                     from "@relcu/rc";
import { AutoComplete, Form, InputGroup }                  from "@relcu/rc";
import { ToggleDropdown }                                  from "@relcu/rc";
import { Icon }                                            from "@relcu/rc";
import { Button }                                          from "@relcu/rc";
import { Stack }                                           from "@relcu/rc";
import { Typography }                                      from "@relcu/rc";
import { Toolbar }                                         from "@relcu/rc";
import { Sidenav }                                         from "@relcu/rc";
import { Page }                                            from "@relcu/rc";
import { omit }                                            from "@relcu/ui";
import { useSource }                                       from "@relcu/ui";
import { confirmModal }                                    from "@relcu/ui";
import { CircularLoader }                                  from "@relcu/ui";
import { fullScreenVar }                                   from "../../../../reactiveVars";
import { CreateEmailTemplateFieldsInput }                  from "../../../../types/graphql-global-types";
import { GenerationListCard }                              from "../../../Generation";
import { RelayQuery }                                      from "../../../Relay";
import { useSchemaField }                                  from "../../useSchemaField";
import { GetMailTemplates }                                from "./__types__/GetMailTemplates";
import { Content }                                         from "./Content/Content";
import { Content as RCContent }                            from "@relcu/rc";
import { EmptyMailTemplateState }                          from "./EmptyMailTemplateState";
import { UpdateMailTemplate, UpdateMailTemplateVariables } from "./__types__/UpdateMailTemplate";
import { RemoveEmailTemplateVariables }                    from "./__types__/RemoveEmailTemplate";
import { RemoveEmailTemplate }                             from "./__types__/RemoveEmailTemplate";
import { DuplicateEmailTemplateVariables }                 from "./__types__/DuplicateEmailTemplate";
import { DuplicateEmailTemplate }                          from "./__types__/DuplicateEmailTemplate";
import { GetMailTemplates_emailTemplates_edges_node }      from "./__types__/GetMailTemplates";
import { SUBSCRIBE_MAIL_TEMPLATES }                        from "./MailTemplateViewQueries";
import { GET_MAIL_TEMPLATES }                              from "./MailTemplateViewQueries";
import { REMOVE_EMAIL_TEMPLATE }                           from "./MailTemplateViewQueries";
import { DUPLICATE_EMAIL_TEMPLATE }                        from "./MailTemplateViewQueries";
import { UPDATE_EMAIL_TEMPLATE }                           from "./MailTemplateViewQueries";

const states = [
  {
    label: "Enabled",
    value: "enabled",
    action: "Status"
  },
  {
    label: "Disabled",
    value: "disabled",
    action: "Status"
  }
];
const icons = {
  "html": "code",
  "builder": "dashboard_customize",
  "plainText": "article"
};

function getMailTemplateCreatePermission(permissions, options) {
  return options.filter(d => permissions[ d.value ] === true);
}

export const MailTemplateView: FC<any> = React.memo(function MailTemplateView(props) {
  const { $viewer, $object } = useSource();
  const navigate = useNavigate();
  const params = useParams();
  const [objectId] = params[ "*" ].split("/");
  const { options } = useSchemaField("EmailTemplate", "type");
  const isFullScreen = useReactiveVar(fullScreenVar);
  const [expand, setExpand] = useState(true);
  const [onUpdate] = useMutation<UpdateMailTemplate, UpdateMailTemplateVariables>(UPDATE_EMAIL_TEMPLATE);
  const [duplicateEmail] = useMutation<DuplicateEmailTemplate, DuplicateEmailTemplateVariables>(DUPLICATE_EMAIL_TEMPLATE);
  const [removeEmail] = useMutation<RemoveEmailTemplate, RemoveEmailTemplateVariables>(REMOVE_EMAIL_TEMPLATE, {
    refetchQueries: ["GetMailTemplates"]
  });
  const [search, setSearch] = useState(null);
  const [filters, setFilters] = useState([]);
  const where = useMemo(() => {
    const query = {};
    if ($object.className !== "Settings") {
      query[ "owner" ] = {
        have: {
          id: {
            equalTo: $object?.id
          }
        }
      };
    } else{
      query[ "owner" ] = {
        exists: false
      };
    }

    if (search) {
      query[ "title" ] = {
        matchesRegex: `^${search}`,
        options: "i"
      };
    }
    const typeFilters = filters.filter(f => f != "disabled" && f != "enabled");
    const stateFilters = filters.filter(f => f == "disabled" || f == "enabled");

    if (stateFilters.length < 2) {
      if (filters.includes("enabled")) {
        query[ "enabled" ] = {
          equalTo: true
        };
      } else if (filters.includes("disabled")) {
        query[ "enabled" ] = {
          equalTo: false
        };
      }
    }

    if (typeFilters?.length) {
      query[ "type" ] = {
        in: typeFilters
      };
    }

    return query;
  }, [search, filters, $object]);
  const subscriptionWhere = useMemo(() => {
    const subscriptionWhere = { ...where };

    if ($object.className !== "Settings") {
      subscriptionWhere[ "owner" ] = {
        have: {
          link: $object?.id
        }
      };
    }
    return subscriptionWhere;
  }, [where, $object]);

  const opts = options.map(option => ({
    ...option,
    icon: icons[ option.value ],
    action: "Type"
  }));
  const permissions = $object.className == "Settings" ? opts.reduce((prev, current) => {
    return {
      ...prev,
      [ current.value ]: true
    };
  }, {}) : ($object.emailTemplateEditorPermissions ?? {});
  const createPermissions = getMailTemplateCreatePermission(permissions, opts);
  const hasPermission = createPermissions.length > 0 && ($object.className == "Settings" || ($object.id === $viewer.id) || $viewer.role == "admin");
  const onUpdateItem = useCallback((id: string, title: string) => {
    onUpdate({
      variables: {
        input: {
          id,
          fields: {
            title
          }
        }
      }
    }).catch(console.error);
  }, []);
  const handleRemove = async (id: string, redirectId: string, type: string) => {
    try {
      await confirmModal({
        title: "Delete confirmation",
        subTitle: `Are you sure you want to delete email template?`,
        content: `All information related with this email template will be lost permanently`,
        label: "DELETE"
      });
      await removeEmail({
        variables: {
          id
        }
      });

      if (redirectId) {
        navigate(`${redirectId}/${type}`);
      } else {
        fullScreenVar(false);
      }
    } catch (e) {
    }
  };
  const handleDuplicate = (data: GetMailTemplates_emailTemplates_edges_node) => {
    const fields = {
      ...omit(data, ["owner", "createdAt", "id", "updatedAt", "__typename", "ACL", "objectId", "objectIcon", "to", "from", "loanProposal", "lead"]),
      title: `${data.title}_copy`
    };
    if ($object.className != "Settings") {
      fields[ "owner" ] = {
        link: $viewer.id
      };
    }
    duplicateEmail({
      variables: {
        input: {
          fields: fields as CreateEmailTemplateFieldsInput
        }
      }
    });
  };

  const handleCreate = async (type: string) => {
    navigate(`create/${type}`);
  };

  const ToggleSelect = React.forwardRef<HTMLDivElement>((props, ref) => {
    //todo add orange indicator
    return <Icon type={"filter_alt"} ref={ref} style={{ color: "var(--rc-accent-03-primary)", cursor: "pointer" }}/>;
  });

  return <RelayQuery<GetMailTemplates>
    className={"EmailTemplates"}
    rowHeight={48}
    query={{
      document: GET_MAIL_TEMPLATES,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-first",
      variables: { where }
    }}
    subscription={{
      document: SUBSCRIBE_MAIL_TEMPLATES,
      variables: { where: subscriptionWhere }
    }}
    render={renderProps => {
      const {
        scrollContainerRef,
        beforeLoaderRef,
        afterLoaderRef,
        loading,
        data: { emailTemplates: { edges = [], pageInfo } } = {}
      } = renderProps;
      const selected = edges.find(e => e.node.objectId === objectId)?.node;
      const activeIndex = edges.findIndex(e => e.node.objectId == objectId);
      const nextOrPrevTemplate = activeIndex + 1 < edges.length ?
        activeIndex + 1 :
        activeIndex - 1 >= 0 ? (activeIndex - 1) : null;
      const navigateNode = edges[ nextOrPrevTemplate ]?.node;
      const navigateId = navigateNode?.objectId;
      const navigateType = navigateNode?.type;
      return (
        <>
          {
            (!loading && !edges.length && !search && objectId != "create" && !filters?.length) ?
              <EmptyMailTemplateState
                createPermissions={createPermissions}
                hasPermission={hasPermission}
                handleCreate={handleCreate}/>
              :
              <Page>
                <Page.Sidebar width={expand ? 320 : 0}
                              isFullScreen={isFullScreen}
                              style={{ borderWidth: expand ? "1px" : "0", opacity: expand ? 1 : 0, gap: 8 }}>
                  <Sidenav.Header>
                    <Toolbar spacing={6} size={"sm"}>
                      {
                        <Page.Toggle
                          size={"sm"}
                          expand={expand}
                          onToggle={() => setExpand(expand => !expand)}
                          disabled={edges?.length == 0}/>
                      }
                      <Typography weights={"medium"} variant={"base16"}>Email templates</Typography>
                      <Stack.Item grow={1}/>
                      {
                        (($viewer.id == $object.id) || $object.className == "Settings") &&
                        (
                          hasPermission &&
                          <ToggleDropdown
                            onClick={(event) => event.stopPropagation()}
                            toggleRenderer={
                              <Button
                                size={"xs"}
                                style={{ alignSelf: "end" }}
                                endIcon={<Icon type="keyboard_arrow_down"/>}>
                                CREATE
                              </Button>
                            }
                            placement={"autoVerticalEnd"}>
                            {
                              createPermissions.map((perm, index) => {
                                return <ToggleDropdown.Item
                                  key={index}
                                  eventKey={3}
                                  onSelect={(event) => handleCreate(perm.value)}>
                                  <Icon type={perm.icon as IconType}/>
                                  {perm.label}
                                </ToggleDropdown.Item>;
                              })
                            }
                          </ToggleDropdown>
                        )
                      }
                    </Toolbar>
                    <Stack direction={"column"} spacing={16} style={{ marginLeft: 16, marginRight: 16 }}
                           childrenRenderMode={"clone"}>
                      <InputGroup inside>
                        <InputGroup.Addon>
                          <Icon type={"search"}/>
                        </InputGroup.Addon>
                        <Form.Control onChange={setSearch} name="name" accepter={AutoComplete} placeholder="Search..."/>
                      </InputGroup>
                      {
                        !!filters.length
                          ? <Badge style={{ alignSelf: "end" }}
                                   children={<CheckPicker
                                     menuStyle={{ width: 200 }}
                                     data={[...states, ...opts]}
                                     placement={"autoVerticalEnd"}
                                     value={filters}
                                     onChange={setFilters}
                                     groupBy={"action"}
                                     toggleAs={ToggleSelect}
                                     searchable={false}
                                   />}
                                   size={"sm"}/>
                          : <CheckPicker
                            menuStyle={{ width: 200 }}
                            style={{ alignSelf: "end" }}
                            data={[...states, ...opts]}
                            placement={"autoVerticalEnd"}
                            value={filters}
                            onChange={setFilters}
                            groupBy={"action"}
                            toggleAs={ToggleSelect}
                            searchable={false}
                          />
                      }
                    </Stack>
                  </Sidenav.Header>
                  <Sidenav style={{ height: "calc(100% - 146px)" }} expanded={expand} appearance="subtle">
                    <Sidenav.Body style={{ height: "100%" }}>
                      <div
                        className={"mail-list-container"}
                        style={{ height: "100%" }}
                        ref={scrollContainerRef}>
                        {pageInfo?.hasPreviousPage && <CircularLoader alignSelf={"center"} ref={beforeLoaderRef}/>}
                        {
                          edges.map((item) => {
                            const mail = item.node;
                            return <GenerationListCard
                              canUpdate={hasPermission && ($viewer.emailTemplateEditorPermissions?.[mail.type] || $viewer.role === "admin")}
                              onUpdate={onUpdateItem}
                              objectId={mail.objectId}
                              onRemove={() => handleRemove(mail.id, navigateId, navigateType)}
                              onDuplicate={(($object?.emailTemplateEditorPermissions?.[ mail.type ] && $object.id === $viewer.id) || $object.className == "Settings") ? () => handleDuplicate(mail) : null}
                              selected={objectId == mail?.objectId}
                              onClick={() => navigate(`${mail.objectId}/${mail.type}`)}
                              type={mail.type || "plainText"}
                              enabled={mail.enabled}
                              title={mail.title}
                              date={mail.createdAt}
                              key={mail.id}/>;
                          })
                        }
                        {
                          loading ?
                            <Stack
                              alignItems={"center"}
                              justifyContent={"center"}
                              style={{ flex: 1 }}>
                              <CircularLoader/>
                            </Stack> : !loading && edges.length == 0 ?
                              <EmptyState
                                title={"No results"}
                                subtitle={search ? "Try again with different term." : null}/> :
                              null
                        }
                        {
                          afterLoaderRef && pageInfo?.hasNextPage &&
                          <CircularLoader alignSelf={"center"} ref={afterLoaderRef}/>
                        }
                      </div>
                    </Sidenav.Body>
                  </Sidenav>
                </Page.Sidebar>
                <Routes>
                  <Route path={"/"} element={
                    <RCContent style={{ justifyContent: "center" }}>
                      {
                        loading ?
                          <CircularLoader/>
                          :
                          (
                            edges.length ?
                              <Navigate to={`${edges[ 0 ].node.objectId}/${edges[ 0 ].node.type}`}/> :
                              <EmptyState icon={"email"} subtitle={`No templates available`}/>
                          )
                      }
                    </RCContent>
                  }/>
                  <Route
                    path={"/:mailTemplateId/:type"}
                    element={
                      (selected || objectId == "create") ?
                        <Content
                          onRemove={(id) => handleRemove(id, navigateId, navigateType)}
                          replaceableFieldsSources={props.replaceableFieldsSources}
                          hasPermissions={hasPermission}
                          onDuplicate={handleDuplicate}
                          setExpand={setExpand}
                          template={selected}
                          expand={expand}
                        />
                        :
                        <RCContent>
                          <EmptyState icon={"email"} subtitle={`No template found`}/>
                        </RCContent>
                    }
                  />
                </Routes>
              </Page>
          }
        </>
      );
    }}
  />;
});
