import { useField }            from "@relcu/final-form";
import React                   from "react";
import { FC }                  from "react";
import { useEffect }           from "react";
import { useMemo }             from "react";
import { SelectPicker }        from "@relcu/rc";
import { usePointerListField } from "../../../../Field/PointerListField/usePointerListField";

export const UserPreview: FC<any> = React.memo(function UserPreview(props) {
  const { input: { onChange: scopeOnChange } } = useField("previewSettings.scope");
  const { options: users, setQ, setSelectedIds } = usePointerListField({
    targetClass: "User",
    ids: props.value ? [props.value[ "objectId" ]] : []
  });
  const data = useMemo(() => {
    const data = [];
    users.forEach(scope => {
      if (scope.options) {
        scope.options.forEach(option => {
          data.push({ ...option, classLabel: scope.label });
        });
      } else {
        data.push(scope);
      }
    });
    return data;
  }, [users]);

  const handleChange = (id) => {
    if (!id) {
      props.onChange(id);
      return;
    }
    scopeOnChange(null);
    props.onChange(data.find(d => d.id == id));
  };

  useEffect(() => {
    setSelectedIds(props.value ? [props.value[ "objectId" ]] : []);
  }, [props.value]);

  return <SelectPicker
    {...props}
    value={props.value[ "id" ] ?? null}
    cleanable={true}
    labelKey={"objectName"}
    valueKey={"id"}
    data={data}
    onSearch={(searchKeyword) => setQ(searchKeyword)}
    onChange={handleChange}
    style={{ width: "100%" }}
  />;
});
