import React         from "react";
import { useEffect } from "react";
import { useRef }    from "react";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "email-visualizer": any;
    }
  }
}

export interface EmailProps {
  template: string
  collapse?: boolean
}
export const Email = React.forwardRef<any,EmailProps>(function Email(props,ref) {
  const { template } = props;
  const element = useRef<any>();
  useEffect(() => {
    element?.current?.renderEmail({ ref,template, collapse: props.collapse || false });
  }, [template]);

  return <email-visualizer ref={element} style={{maxWidth: 640}}/>;
});

export default Email;
