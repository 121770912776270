import React                  from "react";
import { useContext }         from "react";
import { useCallback }        from "react";
import { useForm }            from "@relcu/form";
import { OnChange }           from "@relcu/form";
import { FormSpy }            from "@relcu/form";
import { Field }              from "@relcu/form";
import { Form }               from "@relcu/form";
import { Typography }         from "@relcu/rc";
import { SectionMessage }     from "@relcu/rc";
import { Advice }             from "@relcu/rc";
import { Icon }               from "@relcu/rc";
import { IconButton }         from "@relcu/rc";
import { useClassNames }      from "@relcu/rc";
import { Stack }              from "@relcu/rc";
import { Form as RcForm }     from "@relcu/rc";
import { Content }            from "@relcu/rc";
import { Container }          from "@relcu/rc";
import { FormField }          from "@relcu/rc";
import { defaultMutators }    from "@relcu/ui";
import { useSource }          from "@relcu/ui";
import { MailMessage }        from "../../../../../../graph/__types__/MailMessage";
import Email                  from "../../../../../EmailVisualizer";
import { EmailRenderer }      from "../../../../../EmailVisualizer";
import { PointerPicker }      from "../../../../Field/PointerPicker";
import { PointerPickerProps } from "../../../../Field/PointerPicker";
import { Editor }             from "../../Editor/Editor";
import { FieldsAdjuster }     from "../../FieldsAdjuster";
import { ComposeHeader }      from "../../Header/ComposeHeader";
import { HeaderProps }        from "../../Header/ThreadHeader";
import { Attachments }        from "../../MailAttachmentUpload/Attachments";
import { UploadButton }       from "../../MailAttachmentUpload/UploadButton";
import { MailAutoSuggest }    from "../../MailAutoSuggest";
import { ContentContext }     from "../Content";
import { SendBtn }            from "../SendBtn";
import { MailFlyerAttachment } from "./MailFlyerAttachment";
import { useCompose }         from "./useCompose";
import "./compose.css";

export interface ComposeProps extends HeaderProps {
  initialValues?: {
    subject: string,
    template?: string,
    attachments: any[],
    loanEstimateId?: string,
    loanProposalId?: string,
    html: string,
    cc?: string[],
    to: string[],
    from: string
  };
  mode?: "compose" | "reply" | "expanded";
  draftId?: string;
  onSubmit?(data);
  onDelete?(id: string);
  draftReplyToMessage?: MailMessage;
}

export const Compose = React.forwardRef(function Compose(props: ComposeProps, ref: React.MutableRefObject<any>) {
  const {
    to,
    mailBoxes,
    initialValues,
    contentRendererRef,
    proposalId,
    canUpdate,
    previewHtml,
    handleSubmit,
    handleFormChange,
    handleDraftDelete,
    templateFilters,
    warning,
    handleAttachmentChange,
    emailSignature,
    signatureRendererRef,
    signatureSettings,
    memberContacts,
    saveDraftLoading,
    sendingMail,
    canAttachFlyer,
  } = useCompose(props);

  const { $object: node, $viewer: user } = useSource();
  const { conversation: { scope } } = useContext(ContentContext);
  const { expand, onToggle, draftId, mode = "compose", draftReplyToMessage } = props;
  const { withClassPrefix, prefix } = useClassNames("compose-content", "rc");
  return <Container style={{ overflowX: "auto", overflow: "hidden" }} className={mode ? `rs-container-${mode}` : ""}>
    <Form
      mutators={{ ...defaultMutators }}
      initialValues={initialValues}
      keepDirtyOnReinitialize={true}
      onSubmit={handleSubmit}>
      {({ handleSubmit, submitting, form }) => {
        if (ref) {
          ref.current = form;
        }
        return <>
          {
            mode == "compose" &&
            <ComposeHeader expand={expand} onToggle={onToggle}/>
          }
          <OnChange name={"attachments"} children={(value, previous) => {
            handleAttachmentChange(value, previous, form.getState().values);
          }}/>
          <FieldsSpy names={["to", "cc", "from", "subject", "template", "html"]} onChange={handleFormChange}/>
          <Stack direction={"column"} alignItems={"stretch"} className={"rc-mail-compose-header"}>
            <Stack.Item className={"rc-mail-compose-header-content"}>
              <FieldsAdjuster>
                <FieldsAdjuster.Row label={"To"} static>
                  <Field name={"to"}>
                    {({ meta, input }) => {
                      return <MailAutoSuggest name={"To"} selected={input.value} onChange={input.onChange} data={to}/>;
                    }}
                  </Field>
                </FieldsAdjuster.Row>
                <FieldsAdjuster.Row label={"Cc"}>
                  <Field name={"cc"}>
                    {({ meta, input }) => {
                      return <MailAutoSuggest selected={input.value} onChange={input.onChange} data={to}
                                              showLength={2}/>;
                    }}
                  </Field>
                </FieldsAdjuster.Row>
                <FieldsAdjuster.Row label={"From"}>
                  <RcForm.Select
                    name="from"
                    properties={{
                      labelKey: "address",
                      searchable: false,
                      valueKey: "address",
                      appearance: "subtle",
                      placeholder: "Select template",
                      placement: "autoVerticalEnd",
                      style: { width: "100%" },
                      disabledItemValues: mailBoxes.filter(mb => mb.disabled).map(mb => mb.address)
                    }}
                    size={"sm"}
                    data={mailBoxes}
                  />
                </FieldsAdjuster.Row>
                <FieldsAdjuster.Row label={"Template"}>
                  <FormField<PointerPickerProps>
                    component={PointerPicker}
                    name="template"
                    // format={(value, name) => value?.objectId ?? null}
                    // parse={(value) => {
                    //   return templates.find(t => t.objectId == value);
                    // }}
                    properties={{
                      size: "sm",
                      appearance: "subtle",
                      labelKey: "objectName",
                      valueKey: "objectId",
                      fields: ["content", "subject"],
                      filters: templateFilters,
                      targetClass: "EmailTemplate",
                      style: { width: "100%" },
                      cleanable: true,
                      placeholder: "Please select",
                      placement: "autoVerticalEnd"
                    }}
                  />
                </FieldsAdjuster.Row>
                {
                  canAttachFlyer &&
                  <FieldsAdjuster.Row label={"Flyer attachment"}>
                    <MailFlyerAttachment sendingMail={sendingMail}/>
                  </FieldsAdjuster.Row>
                }
              </FieldsAdjuster>
            </Stack.Item>
          </Stack>
          <Content className={withClassPrefix()}>
            <Stack className={prefix`body`} direction={"column"} alignItems={"stretch"}>
              <Stack.Item grow={1}>
                <Field name={"html"}>
                  {({ input: { value, onChange } }) => (
                    <Field name="template" subscription={{ value: true }}>
                      {({ input }) => (
                        !input.value ? (
                          <div
                            style={{ height: draftReplyToMessage ? "160px" : "100%", overflow: draftReplyToMessage ? "auto" : null }}>
                            <Editor value={value} onChange={onChange}/>
                          </div>
                        ) : (
                          <div className={"rc-mail-editor-container"}>
                            <div className={"rc-mail-editor-template-container"}>
                              <Field name={"to"}>
                                {({ input: { value: selectedToEmails, onChange } }) => {
                                  return <EmailRenderer
                                    onRender={({ subject }) => {
                                      //@ts-ignore
                                      subject && form.change("subject", subject);
                                    }}
                                    ids={{
                                      templateId: input.value,
                                      loanProposalId: proposalId,
                                      fromId: user.objectId,
                                      scopeId: node.__typename === "Lead" ? node.id : (scope?.id),
                                      contactId: node.__typename === "Contact" && node.objectId
                                    }}
                                    ref={contentRendererRef}
                                    toEmails={selectedToEmails.map(m => m.address)}
                                  />;
                                }}
                              </Field>
                            </div>
                          </div>
                        )
                      )}
                    </Field>
                  )}
                </Field>
              </Stack.Item>
              {
                !!previewHtml && !draftReplyToMessage &&
                <Stack.Item className={"padding16"}>
                  <Email template={previewHtml}/>
                </Stack.Item>
              }
              {
                emailSignature && <Stack.Item className={"padding16"}>
                  <Field name={"from"}>
                    {({ input: { value: fromEmail, onChange } }) => {
                      const selected = mailBoxes.find((mB) => mB.address === fromEmail);
                      let enabled = false;
                      const domainType = selected?.domain.provider.type;
                      if (domainType !== "none" && selected?.auth) {
                        enabled = signatureSettings?.enabledMicrosoftGmail;
                      } else {
                        enabled = signatureSettings?.enabledRelcu;
                      }
                      return enabled ? <EmailRenderer
                        ref={signatureRendererRef}
                        template={emailSignature}
                        ids={{
                          fromId: user.objectId
                        }}
                      /> : null;
                    }}
                  </Field>
                </Stack.Item>
              }
            </Stack>
            <Stack direction={"column"} alignItems={"stretch"}>
              <Stack.Item className={"padding16"}>
                <Attachments removeAble={!initialValues.loanEstimateId && !initialValues.loanProposalId}
                             draftReplyToMessage={draftReplyToMessage}/>
              </Stack.Item>
            </Stack>
            {warning ?
              <SectionMessage type={"info"}>
                <Typography color={"primary"} variant={"base14"}>Missing email address</Typography>
                <Typography color={"primary"} variant={"base14"}>{warning}</Typography>
              </SectionMessage> : null}
            <FormSpy
              subscription={{ values: true }}>
              {
                ({ values }) => {
                  if (!warning) {
                    if (!values.subject?.trim()) {
                      return <SectionMessage type={"warning"}>"Subject" is missing. Please add to send the
                        email.</SectionMessage>;

                    } else if (!values.to.length) {
                      return <SectionMessage type={"warning"}>Please choose at least one recipient to send the
                        email.</SectionMessage>;
                    } else if ((node.__typename == "User" && scope) || node.__typename != "User") {
                      const filtered = values.to?.filter(({ address }) => memberContacts.find((t) => t.value === address)) || [];
                      return !filtered.length &&
                        <SectionMessage type={"warning"}>At least one of the recipients must be a loan
                          contact</SectionMessage>;
                    }
                  }
                  return null;
                }
              }
            </FormSpy>
          </Content>
          <Stack spacing={16} justifyContent={"flex-end"} className={"rc-mail-compose-footer"}>
            <Advice text={"Discard draft"}>
              <IconButton
                disabled={!draftId}
                size={"xs"}
                circle
                appearance={"text"}
                icon={<Icon className={"rc-compose-icon"} type={"delete"}/>}
                onClick={() => handleDraftDelete(draftId)}
              />
            </Advice>
            <UploadButton disabled={initialValues.loanEstimateId || initialValues.loanProposalId} canUpdate={canUpdate}
                          name={"attachments"}/>
            <Stack.Item grow={1}/>
            <FormSpy subscription={{ values: true }}>
              {
                ({ values }) => {
                  const filtered = values.to?.filter(({ address }) => memberContacts.find((t) => t.value === address)) || [];
                  return <SendBtn
                    disabled={!canUpdate || submitting || saveDraftLoading || !!warning || !values?.to?.length || !values.from || (!filtered.length && node.__typename != "User") || (!filtered.length && node.__typename == "User" && !!scope)}
                    onClick={handleSubmit}/>;
                }
              }
            </FormSpy>
          </Stack>
        </>;
      }}
    </Form>
  </Container>;
});

export interface FieldsSpyProps {
  names: string[];
  onChange: (values: { [ name: string ]: any }) => void;
}
export const FieldsSpy = React.memo<FieldsSpyProps>(function FieldsSyp(props) {
  const { names, onChange } = props;
  const form = useForm();

  function compareArray(arr, arr1, compareKey = "contactId") {
    // if the other array is a falsy value, return
    if (!arr || !arr1) {
      return false;
    }
    // if the argument is the same array, we can be sure the contents are same as well
    if (arr === arr1) {
      return true;
    }
    // compare lengths - can save a lot of time
    if (arr1.length != arr.length) {
      return false;
    }

    for (let i = 0, l = arr1.length; i < l; i++) {
      // Check if we have nested arrays
      if (arr1[ i ] instanceof Array && arr[ i ] instanceof Array) {
        // recurse into the nested arrays
        if (!arr1[ i ].equals(arr[ i ])) {
          return false;
        }
      } else if (arr1[ i ][ compareKey ] != arr[ i ][ compareKey ]) {
        // Warning - two different object instances will never be equal: {x:20} != {x:20}
        return false;
      }
    }
    return true;
  }

  const handleFeeChange = useCallback((name, value, previous) => {
    if ((name == "to" || name == "cc") && compareArray(value, previous)) {
      return;
    }

    onChange({
      ...form.getState().values,
      [ name ]: value
    });
  }, [form, onChange]);

  return (
    <>
      {names.map(name => {
        return <OnChange key={name} name={name}
                         children={(value, previous) => handleFeeChange(name, value, previous)}/>;
      })}
    </>
  );
});
